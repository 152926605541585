const all_roles = ['admin', 'team_coordinator', 'technical_coordinator', 'designer', 'commercial']
import { useTranslation } from 'react-i18next'

const navigation = () => {
  const { t } = useTranslation()
  const isPT = process.env.NEXT_PUBLIC_COUNTRY === 'PT'
  const isMX = process.env.NEXT_PUBLIC_COUNTRY === 'MX'

  const baseNavigation = [
    {
      path: `/admin`,
      icon: 'mdi:administrator',
      title: t('navigation.admin'),
      acl: ['admin']
    },
    {
      path: `/overview-new`,
      icon: 'wpf:survey',
      title: t('navigation.overview'),
      acl: all_roles
    },
    {
      path: `/overview/${new Date().getFullYear()}`,
      icon: 'mdi:account-group',
      title: t('navigation.clients'),
      acl: all_roles
    },
    {
      path: '/dashboard',
      icon: 'material-symbols:bar-chart-4-bars-rounded',
      title: t('navigation.dashboard'),
      acl: all_roles
    },
    {
      path: `/projects`,
      icon: 'ic:round-view-list',
      title: t('navigation.toAssign'),
      acl: ['team_coordinator']
    },
    {
      path: `/statistics`,
      icon: 'streamline:programming-browser-dashboard-app-code-apps-programming-window-dashboard-performance',
      title: t('navigation.monthlyProduction'),
      acl: ['team_coordinator', 'technical_coordinator', 'designer']
    },
    {
      path: `/myProjects`,
      icon: 'mdi:folder-open',
      title: t('navigation.myProjects'),
      acl: ['technical_coordinator', 'designer']
    },
    {
      path: `/projectReview`,
      icon: 'healthicons:magnifying-glass-outline',
      title: t('navigation.projectReview'),
      acl: ['technical_coordinator']
    },
    {
      path: `/stats`,
      icon: 'mdi:chart-line',
      title: t('navigation.statistics'),
      acl: ['admin']
    },
    {
      path: `/library`,
      icon: 'mdi:image-multiple',
      title: t('navigation.library'),
      acl: ['beta']
    }
  ]

  // Only add Auto Simu and Planka if not MX
  const nonMXNavigation = !isMX
    ? [
        {
          path: `/auto-simu`,
          icon: 'mdi:weather-night',
          title: 'Auto SIMU [BETA]',
          acl: ['beta'],
          externalLink: true,
          openInNewTab: true
        },
        {
          path: `https://planka.dsproject.pt/`,
          icon: 'mdi:trello',
          title: t('navigation.planka'),
          acl: all_roles,
          externalLink: true,
          openInNewTab: true
        }
      ]
    : []

  const ptOnlyNavigation = isPT
    ? [
        {
          path: `/help`,
          icon: 'material-symbols:help-outline',
          title: t('navigation.help'),
          acl: all_roles
        },
        {
          path: `https://blacherestudio.com/`,
          icon: 'mdi-lightbulb',
          title: t('navigation.blachereStudio'),
          acl: all_roles,
          externalLink: true,
          openInNewTab: true
        }
      ]
    : []

  return [...baseNavigation, ...nonMXNavigation, ...ptOnlyNavigation]
}

export default navigation
